import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BGSlideshow from "../components/bg_slideshow"

import FleetEntry from "../components/fleet_entry"
import Accordion from "../components/accordion"

import styles from "./index.module.scss"

import SL1 from "../images/58409697_856610401342406_215162262868200616_n.jpg"
import SL2 from "../images/30855553_1809454216017581_76662992980148224_n.jpg"
import SL3 from "../images/19932854_1526340670720859_5063595210227843072_n.jpg"
import SL4 from "../images/17587439_450062775326325_7680981678075936768_n.jpg"
import SL5 from "../images/17437908_1874754402804732_5731296641449721856_n.jpg"
import SL6 from "../images/18013868_1188090817967158_740491257054232576_n.jpg"
import SL7 from "../images/61520003_161076254931975_1245127992810051582_n.jpg"

import Pritsche from "../images/pritsche.jpg"
import Pritsche2 from "../images/pritsche-2.jpg"
import Tieflader from "../images/tieflader.jpg"
import Tieflader3 from "../images/Tieflader3.jpg"
import Tiefbett from "../images/tiefbett.jpg"
import Tiefbett2 from "../images/Tiefbett3.jpg"
import Begleit from "../images/begleitfahrzeug.jpg"
import Begleit2 from "../images/Begleit3.jpg"


export default () => (
    <Layout allwaysTop={false} active="/fuhrpark">
        <SEO title="Fuhrpark" />
        <BGSlideshow style={{zIndex:-1}} entries={
                    [
                        {bgImage: SL1, animationDelay: 0, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL2, animationDelay: 6, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL3, animationDelay: 12, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL4, animationDelay: 18, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL5, animationDelay: 24, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL6, animationDelay: 30, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                        {bgImage: SL7, animationDelay: 36, animationDuration: 42, xPercent: '50%', yPercent: '50%'},
                    ]} />

        <article style={{background:'white'}}>
        <div className={styles.content_div} style={{marginTop: '100vh'}}>
            <h2>Fuhrpark</h2>
            <p>Unsere Tieflader bringen (fast) alles ans Ziel. Zur Lösung Ihrer Transportaufgaben, gleich, ob es sich um große Abmessungen, hohe Gewichte oder sperrige Güter handelt, können wir Ihnen abgestimmt auf das jeweilige Transportgut, ein Spezialfahrzeug zur Verfügung stellen.</p>
        
            <Accordion header="Pritschen und Telesattel" image={Pritsche}>
                <FleetEntry image={Pritsche} 
                    title="Pritschen und Telesattel" 
                    entries={[
                        {name:"Ladelänge", desc:"13,50m - 28,50m"},
                        {name:"Ladehöhe", desc:"1,40m - 1,50m"},
                        {name:"Zubehör", desc:"Rungen, Seitenklappen"},
                        {name:"Nutzlast", desc:"bis 42t"},
                    ]} />
                    <div>
                    <img src={Pritsche2} style={{width: '100%'}} alt="Pritsche"/>
                    <p>Mit Ladelängen von 13,50 m bis 28,50 m und Ladehöhe von 1,40 - 1,50 m setzen wir diese Fahrzeuge vorwiegend zum Transport von Containern und beispielsweise Baustellenmaterial, Kranteilen sowie zum Transport von Langmaterialien wie Leimholzbinder, Schienen, Rotorblätter und Turmsegmenten für Windkraftanlagen ein. Wahlweise kommen 2- und 3-achsige Zugmaschinen zum Einsatz.</p>
                    </div>
            </Accordion>

            <Accordion header="Tieflader" image={Tieflader}>
                <FleetEntry image={Tieflader} 
                    title="Tieflader" 
                    entries={[
                        {name:"Ladelänge", desc:"8,50m - 29,50m"},
                        {name:"Ladehöhe", desc:"0,87 - 0,95m"},
                        {name:"Zubehör", desc:"Auffahrrampe, Langmaterialböcke, Rungen"},
                        {name:"Nutzlast", desc:"bis 91t"},
                    ]}
                />
                <div>
                    <img src={Tieflader3} style={{width: '100%'}} alt="Tieflader"/>
                    <p>Unsere Tieflader mit einer Ladehöhe von 0,87 m bis 0,95 m und einer Ladelänge von 8,50 bis 29,50 m mit zusätzlichem Laderaum auf dem Schwanenhals werden vorwiegend zum Transport von unterschiedlichsten Anlagen-, Kranteilen und dem Transport von Baumaschinen eingesetzt. Unser Personal verlädt die meisten Baumaschinen selbst, wobei auch der Be- und Entladevorgang durch uns versichert ist. Eine Versicherung, die wir im übrigen noch nie brauchten. Eingesetzt werden hier ebenfalls 2-, 3- und 4-achsige Zugmaschinen.</p>
                </div>
            </Accordion>

            <Accordion header="Tiefbettfahrzeuge" image={Tiefbett}>
                <FleetEntry image={Tiefbett} 
                    title="Tiefbettfahrzeuge" 
                    entries={[
                        {name:"Ladelänge im Bett", desc:"7,00m - 17,20m"},
                        {name:"Ladehöhe", desc:"0,25 - 0,60m"},
                        {name:"Zubehör", desc:"Kesselbrückenverlängerung, Verlängerungsträger"},
                        {name:"Nutzlast", desc:"bis 45t"},
                    ]}
                />
                <div>
                    <img src={Tiefbett2} style={{width: '100%'}} alt="Tiefbettfahrzeug"/>
                    <p>Zum Transport von hohen Ladungen stehen unsere Tiefbettfahrzeuge bereit. Mit einer Ladehöhe von 0,25 m bis 0,60 m und einer Teleskopierbarkeit bis 17,20 m eignen sich diese Fahrzeuge besonders zum Transport von Raumzellen und Baumaschinen. Alle Fahrzeuge sind mit abfahrbarem Schwanenhals konzipiert und somit auffahrbar. Als Besonderheit verfügen wir über eine Kesselbrückenverlängerung, die es erlaubt unkonventionel Turmsegmente für Windkraftanlagen zu transportieren. Als Zugfahrzeuge kommen hierbei 3- und 4-achsige Sattelzugmaschinen zum Einsatz.</p>
                </div>
            </Accordion>
           
            
            <Accordion header="Begleitfahrzeuge" image={Begleit}>
            <FleetEntry image={Begleit} 
                title="Begleitfahrzeuge" 
                entries={[
                    {name:"Besonderheiten", desc:"auch neutrale Fahrzeuge verfügbar"},
                ]}
            />
            <div>
                <img src={Begleit2} style={{width: '100%'}} alt="Begleitfahrzeuge"/>

                <p>Unsere Begleitfahrzeuge sind nach dem gesetzlichen Vorschiften der RGST´92 ausgerüstet, verfügen über die nötigen gesetzlichen Freigaben und wir setzen auf geschultes Personal. Somit sichern wir Ihren Schwertransport professionell ab und tragen dafür Sorge, dass andere Verkehrsteilnehmer nicht gefährdet werden.</p>
                <p>Um äußerst flexibel reagieren zu können, eine hohe Kapazität zu gewährleisten und dabei effektiv zu sein, arbeiten wir mit kompetenten Partnern in ganz Deutschland und im benachbartem Ausland eng zusammen.</p>
            </div>
    </Accordion>
 <p>Neben diversen Begleitfahrzeugen und Werkstattwagen befinden sich im Rostocker Fuhrpark Sattelzugmaschinen mit den Achsformeln 6x2, 6x4 und 8x4. Alle auf dem neusten Stand der Technik, mit sauberer ad-blue Technologie. So tun wir ganz nebenbei etwas für die Umwelt, sparen Kraftstoff und haben den CO2-Ausstoß in den letzten Jahren auf ein Minimum reduziert.</p>
        </div>
        </article>
    </Layout>
)