import React from "react"
import fleetStyle from "./fleet_entry.module.scss"


const FleetEntry = props => (
    <div className={fleetStyle.fleet_entry}>

            <table>
                <tbody>
                {props.entries.map((entry, key) => 
                    <tr key={key}>
                        <td>{entry.name}</td>
                        <td>{entry.desc}</td>
                    </tr>
                )}
                </tbody>
            </table>
    </div>
)

export default FleetEntry;
